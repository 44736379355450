<template>
  <div class="d-flex bg-white row min-vh-100">
    <div class="container">
      <div
        class="d-flex flex-row justify-content-center align-self-center fadeInUp"
      >
        <div class="col-12 col-md-6 col-lg-5 py-4 py-md-10">
          <div class="text-center mb-6">
            <a class="mb-5 d-block" :href="'/'">
              <Logo />
            </a>
            <h1 class="mb-2 font-weight-bold">
              Password reset
            </h1>
            <p class="text-muted">
              Enter your email and we'll send you instructions for resetting it.
            </p>
          </div>

          <div class="form-row">
            <div
              class="form-group col-12"
              :class="{ 'is-invalid': errors.has('email') }"
            >
              <label for="email">Email</label>
              <input
                id="email"
                v-model="email"
                v-validate="{ required: true }"
                type="email"
                class="form-control"
                name="email"
                autocomplete="email"
              />
              <small class="mt-2 d-block text-muted"
                >Remember your password?
                <router-link :to="{ name: 'login' }">Login</router-link>.</small
              >
              <span v-show="errors.has('email')" class="invalid-feedback">{{
                errors.first("email")
              }}</span>
            </div>
            <div class="form-group col-12">
              <button
                type="submit"
                class="btn btn-primary btn-block"
                :disabled="loading"
                @click="submitForm()"
              >
                <span
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                Reset password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Svg/Logo";
export default {
  components: { Logo },
  data() {
    return {
      loading: false,
      email: null,
    };
  },
  methods: {
    submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store
            .dispatch("RESET", {
              email: this.email,
            })
            .then(() => {
              //this.$router.push({ name: 'overview' })
              this.loading = false;
              window.Bus.$emit("flash-message", {
                text: "Instructions sent to your email",
                type: "success",
              });
            })
            .catch((err) => {
              this.loading = false;
              window.Bus.$emit("flash-message", {
                text: err.message,
                type: "error",
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
